export const resourceMain = 'wikibus-resource'
export const pageTitle = 'wikibus-page-title'
export const portfolioGalleryMember = 'portfolio-gallery-member'
export const operationList = 'operation-list'
export const operationIcon = 'operation-icon'
export const operationTrigger = 'operation-trigger'
export const operationForm = 'operation-form'
export const operationSelector = 'operation-selector'
export const portfolioProperties = 'portfolio-properties'
export const portfolioSpecializedProperties = 'portfolio-specialized-properties'
export const portfolioProperty = 'portfolio-property'
export const mediaTypeIcon = 'media-type-icon'
export const collectionTableCell = 'collection-table-cell'
export const cmsParts = 'wikibus-cms-parts'
